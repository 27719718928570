import { Component, effect, inject, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { LayoutService } from '@app/_layouts';
import { CURRENT_COMPANY_STORE } from '@domains/company';

import { CoreRouteLayoutConfig } from './constants';

@Component({
  standalone: true,
  selector: 'app-core-container',
  template: ` <router-outlet></router-outlet>`,
  imports: [RouterOutlet],
})
export class CoreContainerComponent implements OnInit {
  private readonly companyStore = inject(CURRENT_COMPANY_STORE);
  private readonly companyID = this.companyStore.id;

  constructor(private layoutService: LayoutService) {
    effect(
      () => {
        const companyID = this.companyID();
        this.layoutService.updateLayoutState({
          ...CoreRouteLayoutConfig,
          basePath: companyID || '',
        });
      },
      { allowSignalWrites: true }
    );
  }

  ngOnInit(): void {}
}
