import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';

import { Observable } from 'rxjs';
import { take, filter, switchMap } from 'rxjs/operators';

import { Store, select } from '@ngrx/store';

import { selectIsLoaded } from '../store/selectors/vat-report.selectors';
import * as VatReportActions from '../store/actions/vat-report.actions';
import { PermissionsService } from '../_api/permissions.service';

@Injectable({
  providedIn: 'root',
})
export class VatReportsGuard {
  constructor(
    private store: Store,
    private permissions: PermissionsService
  ) {}

  waitForDataToLoad(): Observable<boolean> {
    return this.store.pipe(
      select(selectIsLoaded),
      filter((loaded) => loaded),
      take(1)
    );
  }

  canActivate(): Observable<boolean | UrlTree> {
    this.store.dispatch(VatReportActions.load({}));
    return this.waitForDataToLoad().pipe(
      switchMap(() => this.permissions.accessGuard('vat_report'))
    );
  }
}
