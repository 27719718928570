import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';

import { Observable } from 'rxjs';

import { PermissionsService } from '../_api/permissions.service';

@Injectable({
  providedIn: 'root',
})
export class SalesLedgerGuard {
  constructor(private permissions: PermissionsService) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.permissions.accessGuard('sales_invoice');
  }
}
