import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';
import { take, filter } from 'rxjs/operators';

import { Store, select } from '@ngrx/store';

import { selectIsLoaded } from '../store/selectors/depreciation.selectors';
import * as DepreciationActions from '../store/actions/depreciation.actions';
import { PermissionsService } from '../_api/permissions.service';

@Injectable({
  providedIn: 'root',
})
export class DepreciationsGuard {
  constructor(private store: Store, private permissions: PermissionsService) {}

  waitForDataToLoad(): Observable<boolean> {
    return this.store.pipe(
      select(selectIsLoaded),
      filter((loaded) => loaded),
      take(1)
    );
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    this.store.dispatch(
      DepreciationActions.load({
        ...route.queryParams,
      })
    );
    return this.waitForDataToLoad()
      .pipe
      // switchMapTo(this.permissions.accessGuard('depreciation'))
      ();
  }
}
