import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';
import { take, filter, switchMap } from 'rxjs/operators';

import { Store, select } from '@ngrx/store';

import * as ExpenseSelectors from '../store/selectors/expense.selectors';
import * as ExpenseActions from '../store/actions/expense.actions';
import { PermissionsService } from '../_api/permissions.service';

@Injectable({
  providedIn: 'root',
})
export class ExpensesGuard {
  constructor(
    private store: Store,
    private permissions: PermissionsService
  ) {}

  waitForDataToLoad(): Observable<boolean> {
    return this.store.pipe(
      select(ExpenseSelectors.selectIsLoaded),
      filter((loaded) => loaded),
      take(1)
    );
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    this.store.dispatch(
      ExpenseActions.load({
        ...route.queryParams,
      })
    );
    return this.waitForDataToLoad().pipe(switchMap(() => this.permissions.accessGuard('expense')));
  }
}
