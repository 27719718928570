import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';

import { Observable } from 'rxjs';

import { Actions } from '@ngrx/effects';

import { PermissionsService } from '../_api/permissions.service';

@Injectable({
  providedIn: 'root',
})
export class TransactionsGuard {
  constructor(private permissions: PermissionsService, private actions: Actions) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.permissions.accessGuard('transaction');
  }
}
