import { CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';

import { filter, map } from 'rxjs/operators';
import { CURRENT_COMPANY_STORE } from '@domains/company';

export const setCompanyGuard: CanActivateFn = (route) => {
  const uuid = route.paramMap.get('companyUuid') || route.paramMap.get('id');
  const store = inject(CURRENT_COMPANY_STORE);
  store.setCompanyUUID(uuid || null);

  return toObservable(store.entity).pipe(
    filter((company) => !!company),
    map(() => true)
  );
};
